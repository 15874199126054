import React, { useState } from "react";
import { Menu } from "antd";
import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import BankList from "./bank";
import HotelList from "./hotel";
import ArchList from "./arch";
import TechList from "./tech";
import ShopList from "./shop";
import "./index.css";

const Customer = () => {
  const [current, setCurrent] = useState("1");
  const onMenuClick = ({ item, key, keyPath, domEvent }) => {
    setCurrent(key);
  };
  return (
    <div className="customer">
      <Menu className="menu" defaultSelectedKeys={["1"]} onClick={onMenuClick}>
        <Menu.Item key="1" icon={<DesktopOutlined />}>
          银行/金融
        </Menu.Item>
        <Menu.Item key="2" icon={<ContainerOutlined />}>
          酒店/服务
        </Menu.Item>
        <Menu.Item key="3" icon={<PieChartOutlined />}>
          建筑/物流
        </Menu.Item>
        <Menu.Item key="4" icon={<ContainerOutlined />}>
          科技/医药
        </Menu.Item>
        <Menu.Item key="5" icon={<ContainerOutlined />}>
          企业/其他
        </Menu.Item>
      </Menu>
      <div className="content">
        <ul className={current === "1" ? "show" : ""} key="1">
          <BankList />
        </ul>
        <ul className={current === "2" ? "show" : ""} key="2">
          <HotelList />
        </ul>
        <ul className={current === "3" ? "show" : ""} key="3">
          <ArchList />
        </ul>
        <ul className={current === "4" ? "show" : ""} key="4">
          <TechList />
        </ul>
        <ul className={current === "5" ? "show" : ""} key="5">
          <ShopList />
        </ul>
      </div>
    </div>
  );
};

export default Customer;
