import React from "react";
import Card from "./card";

import CoSpringImg from "../../assets/customer/co_spring.png";
import CoJixiangImg from "../../assets/customer/co_jixiang.png";
import CoToyotaImg from "../../assets/customer/co_toyota.png";
import CoYongdaImg from "../../assets/customer/co_yongda.png";
import CoMoonImg from "../../assets/customer/co_moon.png";
import CoStarbucksImg from "../../assets/customer/co_starbucks.png";

const Shop = () => {
  return (
    <>
      <Card avatar={CoSpringImg} name="春秋航空" url="https://www.ch.com/" />
      <Card
        avatar={CoJixiangImg}
        name="吉祥航空"
        url="http://www.juneyaoair.com/"
      />
      <Card
        avatar={CoToyotaImg}
        name="丰田汽车"
        url="http://www.toyota.com.cn/"
      />
      <Card
        avatar={CoYongdaImg}
        name="永达汽车"
        url="http://www.ydauto.com.cn/cns/index.html"
      />
      <Card avatar={CoMoonImg} name="月星集团" url="https://www.yuexing.cn/" />
      <Card
        avatar={CoStarbucksImg}
        name="星巴克"
        url="https://www.starbucks.com.cn/"
      />
    </>
  );
};

export default Shop;
