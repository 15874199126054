import React from "react";
import Carousel from "../../components/carousel";
import Title from "../../components/title";
import { PicBoxCompany, PicBoxService } from "../../components/pic-box";
import Contact from "../../components/contact";

const Index = () => {
  return (
    <div>
      <Carousel />
      <Title text="服务范围" />
      <PicBoxService />
      <Title text="企业介绍" />
      <PicBoxCompany />
      <Title text="联系方式" />
      <Contact />
    </div>
  );
};

export default Index;
