import React from "react";
import {
  Map,
  Marker,
  NavigationControl,
  InfoWindow,
  ScaleControl,
  ZoomControl,
} from "react-bmapgl";
import WeixinImg from "../../assets/contact/weixin.jpg";
import WeixinAccountImg from "../../assets/contact/weixin_account.png";
import "./index.css";

const Contact = () => {
  return (
    <div className="contact">
      <div className="list">
        <div className="item">
          <div className="inner">
            <p>
              <em>手机：</em>
              <a href="tel:15821988198"> 15821988198</a>（严经理）
            </p>
            <p>
              <em>固话：</em>
              <a href="tel:021-58078451"> 021-58078451</a>
            </p>
            <p>
              <em>地址：</em>
              上海市金山区新卫公路790号
            </p>
          </div>
        </div>
        <div className="item">
          <div className="inner">
            <p>
              <em>微信：</em>15821988198（备注：租发电机）
            </p>
            <p>
              <em>邮箱：</em>574758630@qq.com
            </p>
            <p>
              <em>QQ：</em>574758630
            </p>
          </div>
        </div>
        <div className="item item-img">
          <div className="inner">
            <img src={WeixinImg} alt="" />
            <p className="desc">扫我添加微信咨询</p>
          </div>
          <div className="inner">
            <img src={WeixinAccountImg} alt="" />
            <p className="desc">扫我关注微信公众号</p>
          </div>
        </div>
      </div>
      <div className="map">
        <Map
          center={{ lng: 121.301723, lat: 30.73833 }}
          zoom={14}
          tilt={20}
          enableScrollWheelZoom
        >
          <Marker position={{ lng: 121.301723, lat: 30.73833 }} />
          <NavigationControl />
          <ScaleControl />
          <ZoomControl />
          <InfoWindow
            position={{ lng: 121.301723, lat: 30.73833 }}
            text="上海市金山区新卫公路790号"
            title="上海清嘉发电机租赁"
          />
        </Map>
      </div>
    </div>
  );
};

export default Contact;
