import "./App.css";
import React from "react";
import Top from "./components/top";
import Nav from "./components/nav";
import Footer from "./components/footer";
import { BackTop } from "antd";
import RouterIndex from "./router";
import { BrowserRouter as Router } from "react-router-dom";
// import "antd/dist/antd.css";
function App() {
  return (
    <div className="App app-website">
      <Router>
        <Top />
        <Nav />
        <div className="layout">
          <RouterIndex />
        </div>
        <Footer />
        <BackTop />
      </Router>
    </div>
  );
}

export default App;
