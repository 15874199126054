import React from "react";
import { PicBoxCompany } from "../../components/pic-box";

const Index = () => {
  return (
    <div>
      <PicBoxCompany style={{ marginTop: "30px" }} />
    </div>
  );
};

export default Index;
