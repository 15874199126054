import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const Info = (props) => {
  const { text } = props;
  return (
    <div className="info">
      <p>{text}</p>
    </div>
  );
};

Info.propTypes = {
  text: PropTypes.string,
};
export default Info;
