import React from "react";
import { Carousel } from "antd";
import "./index.css";
import Banner_1 from "../../assets/banner/banner_big_1.jpg";
import Banner_2 from "../../assets/banner/banner_big_2.jpg";
import Banner_3 from "../../assets/banner/banner_big_3.jpg";
import Banner_4 from "../../assets/banner/banner_big_4.jpg";

const Comp = () => {
  return (
    <Carousel
      className="carousel"
      autoplay
      swipeToSlide={true}
      arrows={true}
      easing="ease-in-out"
      pauseOnHover={true}
      dots={true}
    >
      <div>
        <h3>
          <img src={Banner_1} alt="" />
        </h3>
      </div>
      <div>
        <h3>
          <img src={Banner_2} alt="" />
        </h3>
      </div>
      <div>
        <h3>
          <img src={Banner_3} alt="" />
        </h3>
      </div>
      <div>
        <h3>
          <img src={Banner_4} alt="" />
        </h3>
      </div>
    </Carousel>
  );
};

export default Comp;
