import React from "react";
import Customer from "../../components/customer";

const Index = () => {
  return (
    <div>
      <Customer />
    </div>
  );
};

export default Index;
