import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./index.css";

const Pic = (props) => {
  const { src, alt, title, text, type } = props;
  return (
    <div className={classnames("pic", type)}>
      <div className="img-box">
        <div className="mask"></div>
        <img src={src} alt={alt} />
      </div>
      <div className="text-box">
        <div className="tit">{title}</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

Pic.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

Pic.defaultProps = {
  type: "normal", // 可选normal|slide
};

export default Pic;
