import React from "react";
import Card from "./card";
import BankConstructionImg from "../../assets/customer/bank_construction.png";
import BankPosterImg from "../../assets/customer/bank_poster.png";
import BankCommerialImg from "../../assets/customer/bank_commercial.png";
import BankAgriculturalImg from "../../assets/customer/bank_agricultural.png";
import BankChinaImg from "../../assets/customer/bank_china.png";
import BankBeijingImg from "../../assets/customer/bank_beijing.png";
import BankWenzhouImg from "../../assets/customer/bank_wenzhou.png";
import BankTianjinImg from "../../assets/customer/bank_tianjin.png";
import BankDalianImg from "../../assets/customer/bank_dalian.png";
import BankXiamenImg from "../../assets/customer/bank_xiamen.png";
import BankBeaImg from "../../assets/customer/bank_bea.png";
import BankEverbrightImg from "../../assets/customer/bank_erverbright.png";

const Bank = () => {
  return (
    <>
      <Card
        avatar={BankCommerialImg}
        name="中国工商银行"
        url="http://www.icbc.com.cn/icbc/"
      />
      <Card
        avatar={BankConstructionImg}
        name="中国建设银行"
        url="http://www.ccb.com/cn/home/indexv3.html"
      />
      <Card
        avatar={BankAgriculturalImg}
        name="中国农业银行"
        url="http://www.abchina.com/cn/"
      />
      <Card
        avatar={BankPosterImg}
        name="中国邮政储蓄银行"
        url="http://www.psbc.com/cn/index.html"
      />
      <Card avatar={BankChinaImg} name="中国银行" url="https://www.boc.cn/" />
      <Card
        avatar={BankBeijingImg}
        name="北京银行"
        url="http://www.bankofbeijing.com.cn/"
      />
      <Card
        avatar={BankWenzhouImg}
        name="温州银行"
        url="http://www.wzcb.com.cn/"
      />
      <Card
        avatar={BankDalianImg}
        name="大连银行"
        url="http://www.bankofdl.com/home/pc/index.shtml"
      />
      <Card
        avatar={BankTianjinImg}
        name="天津银行"
        url="http://www.tccb.com.cn/"
      />
      <Card
        avatar={BankEverbrightImg}
        name="光大银行"
        url="http://www.cebbank.com/"
      />
      <Card
        avatar={BankBeaImg}
        name="东亚银行"
        url="https://www.hkbea.com.cn/PersonalBusiness/"
      />
      <Card
        avatar={BankXiamenImg}
        name="厦门国际银行"
        url="https://www.xib.com.cn/"
      />
    </>
  );
};

export default Bank;
