import React from "react";
import Title from "../../components/title";

const Index = () => {
  return (
    <div>
      <Title text="知识库" />
    </div>
  );
};

export default Index;
