import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const Card = (props) => {
  const { avatar, name, info, url } = props;
  return (
    <li className="card">
      <a href={url} target="_blank" rel="noreferrer">
        <div className="avatar">
          <img src={avatar} alt={name} />
        </div>
        <div className="detail">
          <div className="name">{name}</div>
          <div className="extra">{info}</div>
        </div>
      </a>
    </li>
  );
};

Card.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.string,
  url: PropTypes.string,
};

export default Card;
