import React from "react";
import "./index.css";

const Top = () => {
  return (
    <div className="top">
      <span className="tel">租赁咨询：15821988198（严经理）</span>
    </div>
  );
};

export default Top;
