import React from "react";
import { PicBoxService } from "../../components/pic-box";

const Index = () => {
  return (
    <div>
      <PicBoxService style={{ marginTop: "30px" }} />
    </div>
  );
};

export default Index;
