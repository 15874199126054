import React from "react";
import { Menu } from "antd";
import {
  CommentOutlined,
  BlockOutlined,
  BankOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import "./index.css";

const Nav = ({ location }) => {
  const { pathname } = location;
  const pathKey = pathname.replace("/", "") || "index";

  return (
    <div className="nav">
      <div className="logo"></div>
      <div className="menu">
        <Menu selectedKeys={[pathKey]} mode="horizontal">
          <Menu.Item key="index" icon={<BankOutlined />}>
            <span className="text">
              <Link to="/">首页</Link>
            </span>
          </Menu.Item>
          <Menu.Item key="service" icon={<BlockOutlined />}>
            <span className="text">
              <Link to="/service">服务范围</Link>
            </span>
          </Menu.Item>
          <Menu.Item key="intro" icon={<BankOutlined />}>
            <span className="text">
              <Link to="/intro">企业介绍</Link>
            </span>
          </Menu.Item>
          {/* <Menu.Item key="knowledge" icon={<FileSearchOutlined />}>
            <span className="text">
              <Link to="/knowledge">知识库</Link>
            </span>
          </Menu.Item> */}
          <Menu.Item key="customer" icon={<SafetyOutlined />}>
            <span className="text">
              <Link to="/customer">客户案例</Link>
            </span>
          </Menu.Item>
          <Menu.Item key="contact" icon={<CommentOutlined />}>
            <span className="text">
              <Link to="/contact">联系方式</Link>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default withRouter(Nav);
