import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const Title = (props) => {
  const { text } = props;
  return (
    <h2 className="title">
      <span className="text">{text}</span>
    </h2>
  );
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Title;
