import React from "react";
import Pic from "../../pic";
import Info from "../../info";
import Pic1 from "../../../assets/intro/info_1.jpg";
import Pic2 from "../../../assets/intro/info_2.jpg";
import Pic3 from "../../../assets/intro/info_3.jpg";
import PropTypes from "prop-types";

const PicBoxCompany = (props) => {
  const { style } = props;
  return (
    <div style={style} className="pic-box">
      <ul className="pic-list">
        <li className="item small">
          <Pic src={Pic1} />
        </li>
        <li className="item small">
          <Info text="我们是上海清嘉实业有限公司，专业租赁发电机，承接应急供电等项目。作为上海最早进入发电机业务且规模最大的公司之一，在设备销售、租赁、维修保养、配件供应等方面都有十多年的业务经验，主要服务范围在上海市以及周边长三角地区。我司现有各类发电机一百余台，拥有经验丰富的操作人员和专业的维修技师团队，在本地发电机租赁领域有非常好的口碑。我们合作的客户包含政府城建，银行，医院，大型展会，工厂生产、私营企业、个体老板等，并建立了良好的长期合作关系，现客户资源遍及全国。选择清嘉发电，您就选择了一份安心。" />
        </li>
      </ul>
      <ul className="pic-list">
        <li className="item small">
          <Info text="我们的发电机组分为低噪音和普通型，功率从 5KW 到 1500KW 不等，大、中、小各种类型的发电机组可满足不同用户的需求，并配备专业司机和维修维护人员，提供24小时无间断的服务。 我们的产品均来自一线品牌，包括进口的康明斯机组，和国内一线的上柴，玉柴等品牌设备。有噪音低、性能可靠、稳定性高、节能环保等等优点。可选配柴油电缆等耗材配件，我们会根据您的需求，制定最适合您的电力方案。我们的机器还在持续更新换代，目前在用的发电机组均是近一两年的新设备，现场使用极少出现故障，您的满意是我们最大的动力。" />
        </li>
        <li className="item small">
          <Pic src={Pic2} />
        </li>
      </ul>
      <ul className="pic-list">
        <li className="item small">
          <Pic src={Pic3} />
        </li>
        <li className="item small">
          <Info text="实实在在的经营、随叫随到的贴身服务，使我们成为客户的坚定伙伴、可满足用户长短期用电；面向建筑工地、公路建设、地铁施工、野外作业、商场备用、医院应急、影视媒体、单位供暖等等。我们将依托雄厚的实力，精湛的技术，专业的服务，以较低的市场价格，为广大客户提供优质的服务，可靠的电力供应。停电应急，清嘉电力。只要一个电话，其余我们来做，诚恳欢迎各届人士共赢发展！" />
        </li>
      </ul>
    </div>
  );
};

PicBoxCompany.propTypes = {
  style: PropTypes.object,
};

export default PicBoxCompany;
