import React from "react";
import { Route, Switch } from "react-router-dom";

//首页
import Home from "../pages/home";
import Contact from "../pages/contact";
import Intro from "../pages/intro";
import Customer from "../pages/customer";
import Knowledge from "../pages/knowledge";
import Service from "../pages/service";

const RouterIndex = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/intro" component={Intro} />
      <Route exact path="/customer" component={Customer} />
      <Route exact path="/knowledge" component={Knowledge} />
      <Route exact path="/service" component={Service} />
    </Switch>
  );
};

export default RouterIndex;
