import React from "react";
import Contact from "../../components/contact";

const Index = () => {
  return (
    <div>
      <Contact />
    </div>
  );
};

export default Index;
