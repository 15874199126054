import React from "react";
import Card from "./card";
import ArchCrccImg from "../../assets/customer/arch_crcc.png";
import ArchTowerImg from "../../assets/customer/arch_tower.png";
import ArchGeologyImg from "../../assets/customer/arch_geology.png";
import ArchRiverImg from "../../assets/customer/arch_river.png";
import ArchNineImg from "../../assets/customer/arch_nine.png";
import ArchCscecImg from "../../assets/customer/arch_cscec.png";
import ArchTunnelImg from "../../assets/customer/arch_tunnel.png";
import ArchShuiliImg from "../../assets/customer/arch_shuili.png";
import TransBaishiImg from "../../assets/customer/trans_baishi.png";
import TrasnDepponImg from "../../assets/customer/trans_deppon.png";
import TransSunningImg from "../../assets/customer/trans_suning.png";
import TransTiantianImg from "../../assets/customer/trans_tiantian.png";

const Arch = () => {
  return (
    <>
      <Card
        avatar={ArchCscecImg}
        name="中国建筑"
        url="http://www.cscec.com.cn/?lang=zh"
      />
      <Card avatar={ArchCrccImg} name="中国铁建" url="http://www.crcc.cn/" />
      <Card
        avatar={ArchTowerImg}
        name="中国铁塔"
        url="https://www.china-tower.com/index"
      />
      <Card
        avatar={ArchRiverImg}
        name="江河建设"
        url="http://www.jhjsjt.com/"
      />
      <Card
        avatar={ArchShuiliImg}
        name="上海市水利工程集团"
        url="http://www.shuiligroup.com/"
      />
      <Card
        avatar={ArchTunnelImg}
        name="上海隧道工程有限公司"
        url="https://www.stec.sh.cn/#/home"
      />
      <Card
        avatar={ArchGeologyImg}
        name="中国地质工程"
        url="http://www.cgcsh.cecep.cn/"
      />
      <Card
        avatar={ArchNineImg}
        name="上海久隆电力集团"
        url="https://baike.baidu.com/item/%E4%B8%8A%E6%B5%B7%E4%B9%85%E9%9A%86%E7%94%B5%E5%8A%9B%EF%BC%88%E9%9B%86%E5%9B%A2%EF%BC%89%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/15138481?fr=aladdin"
      />
      <Card
        avatar={TrasnDepponImg}
        name="德邦物流"
        url="https://www.deppon.com/"
      />
      <Card
        avatar={TransSunningImg}
        name="苏宁物流"
        url="https://wuliu.suning.com/#1"
      />
      <Card
        avatar={TransBaishiImg}
        name="百世快递"
        url="http://www.800bestex.com/"
      />
      <Card
        avatar={TransTiantianImg}
        name="天天快递"
        url="https://www.ttkdex.com/"
      />
    </>
  );
};

export default Arch;
