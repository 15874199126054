import React from "react";
import Card from "./card";
import ItZteImg from "../../assets/customer/it_zte.png";
import ItPudongImg from "../../assets/customer/it_pudong.png";
import ItTataImg from "../../assets/customer/it_tata.png";
import ItKangxuImg from "../../assets/customer/it_kangxu.png";
import ItBlizzardImg from "../../assets/customer/it_blizzard.png";
import ItMissfreshImg from "../../assets/customer/it_missfresh.png";
import MedHuarunImg from "../../assets/customer/med_huarun.png";
import MedThermoImg from "../../assets/customer/med_thermo.png";
import MedWoosenImg from "../../assets/customer/med_woosen.png";
import MedYaomingImg from "../../assets/customer/med_yaoming.png";

const Tech = () => {
  return (
    <>
      <Card
        avatar={ItZteImg}
        name="中兴捷维"
        url="https://www.zte.com.cn/china/"
      />
      <Card
        avatar={ItPudongImg}
        name="上海浦东软件园"
        url="http://spsp.com.cn/"
      />
      <Card
        avatar={ItTataImg}
        name="塔塔信息技术"
        url="http://www.tatachina.com.cn/index.php?c=category&catid=17"
      />
      <Card
        avatar={ItKangxuImg}
        name="康旭科技"
        url="http://www.kangxukeji.com/"
      />
      <Card
        avatar={ItBlizzardImg}
        name="暴雪软件上海"
        url="http://cn.blizzard.com/zh-cn"
      />
      <Card
        avatar={ItMissfreshImg}
        name="每日优鲜"
        url="https://www.missfresh.cn/"
      />
      <Card
        avatar={MedHuarunImg}
        name="华润医药"
        url="https://www.crpharm.com/"
      />
      <Card
        avatar={MedThermoImg}
        name="赛默飞世尔科技"
        url="https://www.thermofisher.com/cn/zh/home.html"
      />
      <Card
        avatar={MedWoosenImg}
        name="泽润生物"
        url="http://www.zerunbio.com/"
      />
      <Card
        avatar={MedYaomingImg}
        name="药明生物"
        url="https://www.wuxibiologics.com.cn/"
      />
    </>
  );
};

export default Tech;
