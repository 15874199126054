import React from "react";
import Card from "./card";
import HantingImg from "../../assets/customer/hotel_hanting.png";
import QuanjiImg from "../../assets/customer/hotel_quanji.png";
import RujiaImg from "../../assets/customer/hotel_rujia.png";
import Haiyou from "../../assets/customer/hotel_haiyou.png";
import SevendaysImg from "../../assets/customer/hotel_7.png";
import MotelImg from "../../assets/customer/hotel_motel.png";
import GreenImg from "../../assets/customer/hotel_green.png";
import ViennaImg from "../../assets/customer/hotel_vienna.png";
import AtourImg from "../../assets/customer/hotel_atour.png";
import JjImg from "../../assets/customer/hotel_jj.png";
import MhtImg from "../../assets/customer/hotel_manhatton.png";
import HeyiImg from "../../assets/customer/hotel_heyi.png";
import OrangeImg from "../../assets/customer/hotel_orange.png";
import KaiyuanImg from "../../assets/customer/hotel_kaiyuan.png";

const Hotel = () => {
  return (
    <>
      <Card
        avatar={HantingImg}
        name="汉庭酒店"
        url="https://www.huazhu.com/Hanting"
      />
      <Card
        avatar={QuanjiImg}
        name="全季酒店"
        url="https://www.huazhu.com/Quanji"
      />
      <Card
        avatar={RujiaImg}
        name="如家酒店"
        url="http://www.homeinns.com/homeinn"
      />
      <Card
        avatar={Haiyou}
        name="海友酒店"
        url="https://www.huazhu.com/haiyou"
      />
      <Card
        avatar={SevendaysImg}
        name="7天连锁酒店"
        url="http://www.7daysinn.cn/"
      />
      <Card
        avatar={MotelImg}
        name="莫泰酒店"
        url="http://www.homeinns.com/motel"
      />
      <Card
        avatar={GreenImg}
        name="格林豪泰酒店"
        url="https://www.998.com/shtml/99/zh/index.html"
      />
      <Card avatar={ViennaImg} name="维也纳酒店" url="http://www.wyn88.com/" />
      <Card avatar={AtourImg} name="亚朵酒店" url="http://www.yaduo.com/" />
      <Card avatar={JjImg} name="喆·啡酒店" url="http://www.jjcoffetels.com/" />
      <Card avatar={MhtImg} name="曼哈顿酒店" url="http://www.mhghotel.com/" />
      <Card
        avatar={HeyiImg}
        name="和颐酒店"
        url="https://www.bthhotels.com/About/ytel"
      />
      <Card
        avatar={OrangeImg}
        name="桔子酒店"
        url="https://www.huazhu.com/Juzi"
      />
      <Card
        avatar={KaiyuanImg}
        name="开元酒店"
        url="https://www.kaiyuanhotels.com/"
      />
    </>
  );
};

export default Hotel;
